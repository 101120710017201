import React from 'react'
import './DqMeterModal.scss'
import { Dialog } from 'primereact/dialog'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import warningIcon from '../../../../../resources/images/icon/warning-red.svg'
import { meterUtilityUnits } from '../../../../../utils/helpers/Constants'
import {
  formatNumWODecimal,
  trucateNumWODecimal,
} from '../../../../../services/common/formatter'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'
import { getLocalizedValue } from 'utils/helpers/LanguageUtils'
import PropTypes from 'prop-types'
import { Button } from 'components/atomic/index'

const CHECKBOX_ICON_SELECTOR_CLASS = '.dq-meter-modal-content .meter-modal-datatable .p-datatable-thead th.p-selection-column div.p-checkbox-box span.p-checkbox-icon'

function DqMeterModalV2({
  meterModalGapData,
  meterData,
  metersSelected,
  onEnterDataHandler,
  onRequestDataHandler,
  showDialog,
  onHideHandler,
  updateSelectedMeters,
}) {
  const { meterName, provider, utility, meterComment, floorAreaType } = meterData
  const { summaries } = meterModalGapData
  const {
    loginState: { userInfo },
  } = useLoginAuthContext()
  const selectedLanguage = userInfo.languagePreference

  const getLocalisedMsg = (msgKey) => {
    msgKey = msgKey.toLowerCase()
    if (msgKey === 'missing') {
      msgKey = 'missing_tc'
    }
    return getLocalizedValue(selectedLanguage, `t_${msgKey}`)
  }

  const getMeterModalTitle = () => {
    if (!meterName) return '-'
    return meterComment ? `${meterComment} (${meterName})` : `(${meterName})`
  }

  const selectedMeterDataNotEmpty = () => metersSelected?.length > 0
  const meterModalDataNotEmpty = () => summaries?.length > 0
  
  function addCheckboxClasses(node) {
    node.classList.add('pi', 'pi-check')
  }
  
  function removeCheckboxClasses(node) {
    node.classList.remove('pi', 'pi-check')
  }

  function handleSelectionWithFilledConsumption(newSelectionExceptFilledConsumption, checkboxOffsetParent) {
    if (!newSelectionExceptFilledConsumption) {
      updateSelectedMeters([])
      return
    }
  
    const globalCheckboxIconNode = getGlobalCheckboxIconNode()
    const meterModalDataLength = getMeterModalDataLength()
    const dataExceptFilledConsumption = getDataExceptFilledConsumption()
  
    if (newSelectionExceptFilledConsumption.length !== meterModalDataLength) {
      handleCheckboxLogic(globalCheckboxIconNode, checkboxOffsetParent, newSelectionExceptFilledConsumption, dataExceptFilledConsumption)
    }
  
    updateSelectedMeters(newSelectionExceptFilledConsumption)
  }
  
  function getGlobalCheckboxIconNode() {
    return document.querySelector(CHECKBOX_ICON_SELECTOR_CLASS)
  }
  
  function getMeterModalDataLength() {
    return meterModalDataNotEmpty() ? summaries.length : null
  }
  
  function getDataExceptFilledConsumption() {
    return summaries.filter((selection) => !selection.isConsumption)
  }
  
  function handleCheckboxLogic(globalCheckboxIconNode, checkboxOffsetParent, newSelectionExceptFilledConsumption, dataExceptFilledConsumption) {
    if (!globalCheckboxIconNode) return
  
    if (isHeaderCheckbox(checkboxOffsetParent)) {
      handleHeaderCheckboxClick(globalCheckboxIconNode, newSelectionExceptFilledConsumption)
    } else {
      handleRowCheckboxClick(globalCheckboxIconNode, newSelectionExceptFilledConsumption, dataExceptFilledConsumption)
    }
  }
  
  function isHeaderCheckbox(checkboxOffsetParent) {
    return checkboxOffsetParent && checkboxOffsetParent.tagName === 'TH'
  }
  
  function handleHeaderCheckboxClick(globalCheckboxIconNode, newSelectionExceptFilledConsumption) {
    if (globalCheckboxIconNode.classList.contains('pi-check')) {
      removeCheckboxClasses(globalCheckboxIconNode)
    } else {
      addCheckboxClasses(globalCheckboxIconNode)
    }
  }
  
  function handleRowCheckboxClick(globalCheckboxIconNode, newSelectionExceptFilledConsumption, dataExceptFilledConsumption) {
    if (isAllOrSomeRowsSelected(newSelectionExceptFilledConsumption, dataExceptFilledConsumption)) {
      addCheckboxClasses(globalCheckboxIconNode)
    } else if (newSelectionExceptFilledConsumption.length === 0) {
      removeCheckboxClasses(globalCheckboxIconNode)
    }
  }
  
  function isAllOrSomeRowsSelected(newSelectionExceptFilledConsumption, dataExceptFilledConsumption) {
    return newSelectionExceptFilledConsumption.length === dataExceptFilledConsumption.length ||
           newSelectionExceptFilledConsumption.length > 0
  }

  const onMeterDataSelectionChange = (evt) => {
    let newSelection = evt.value
    let newSelectionExceptFilledConsumption = newSelection.filter(selection => !selection.isConsumption)
    handleSelectionWithFilledConsumption(newSelectionExceptFilledConsumption, evt.originalEvent.target.offsetParent)
  }

  const onShowSelectionCheckbox = (rowData) => {
    if(rowData.estimate === 'Missing'){
      return true
    }
  }

  const getHeaderCheckboxShowCssClass = () => {
    if (summaries?.some(({ isConsumption }) => isConsumption === false)) {
      return 'dq-meter-modal-header-checkbox-show'
    }
    return ''
  }

  const getLabelFloorArea = () => {
    const floorAreaUnitTypes = {
      M2: <span>m<sup>2</sup></span>,
      FT2: <span>ft<sup>2</sup></span>,
    }
    if (!meterData) return <span>-</span>

    let floorArea = meterData.floorArea !== null
      ? formatNumWODecimal(selectedLanguage, trucateNumWODecimal(meterData.floorArea))
      : '-'

    if (floorAreaType) {
      floorArea = (
        <span>
          {floorArea}
          {floorAreaUnitTypes[floorAreaType]}
        </span>
      )
    }

    return <span>{floorArea}</span>
  }

  const getLabelAreaCovered = () => {
    const areaCovered = meterData?.areaCovered || '-'
    return <span>{areaCovered ? getLocalisedMsg(areaCovered) : areaCovered}</span>
  }

  function getHeaderLabelUtility() {
    let headerLabelUtility = '-'
    if (meterData) {
      if (utility) {
        headerLabelUtility = <span>{getLocalisedMsg(utility)}</span>
      }
      if (provider) {
        headerLabelUtility = (
          <span>{`${getLocalisedMsg(utility)} - ${provider}`}</span>
        )
      }
    }
    return headerLabelUtility
  }

  function getDqMeterModalHeaderElement() {
    return (
      <>
        <div className="dq-meter-modal-header-title">
          {getMeterModalTitle()}
        </div>
        <div className="dq-meter-modal-header-labels">
          <div className="header-label label-util">
            {getHeaderLabelUtility()}
          </div>
          <div className="header-label label-floor">
            {getLabelFloorArea()}{' '}
            <span className="label-floor-area">
              {getLocalisedMsg('floor_area')}
            </span>
          </div>
          <div className="header-label label-area">{getLabelAreaCovered()}</div>
        </div>
      </>
    )
  }

  function getDqMeterModalFooterElement() {
    return (
      <div
        className="dq-meter-modal-footer"
        style={{
          visibility: meterModalDataNotEmpty() ? 'visible' : 'hidden',
        }}
      >
        <Button
          disabled={!selectedMeterDataNotEmpty()}
          onClick={() => onRequestDataHandler()}
          content='t_request_data'
        />
        <Button
          disabled={!selectedMeterDataNotEmpty()}
          onClick={() => onEnterDataHandler(metersSelected, onHideHandler)}
          content='t_btn_enter'
        />
    
      </div>
    )
  }

  const dateSummaryBodyTemplate = (rowData) => {
    return rowData.dateSummary ?? '-'
  }

  const estimateBodyTemplate = (rowData) => {
    let msgKeyEstimate = '-'
    if (rowData.estimate) {
      msgKeyEstimate = rowData.estimate.toLowerCase()
      msgKeyEstimate = msgKeyEstimate === 'estimate' ? 'estimated' : msgKeyEstimate
    }
    return <span>{getLocalisedMsg(msgKeyEstimate)}</span>
  }

  const sourceBodyTemplate = (rowData) => {
    return rowData.source ? rowData.source : '-'
  }

  const consumptionBodyTemplate = (rowData) => {
    if (rowData.estimate && rowData.estimate.toLowerCase() === 'missing') {
      return <img src={warningIcon} alt="missing data" />
    }

    if (!rowData.consumption) return '-'

    const consumptionValue = formatNumWODecimal(selectedLanguage, trucateNumWODecimal(rowData.consumption))
    const unit = meterUtilityUnits[utility]

    if (rowData.estimate.toLowerCase() === 'estimate') {
      return <span>{`${consumptionValue} ${unit} (EST)`}</span>
    }

    return `${consumptionValue} ${unit}`
  }

  const getHeaderNode = (header) => <span>{getLocalisedMsg(header)}</span>

  function getMeterDataTable() {
    return (
      <DataTable
        value={summaries}
        selection={metersSelected}
        onSelectionChange={(e) => onMeterDataSelectionChange(e)}
        showSelectionElement={(rowData) => onShowSelectionCheckbox(rowData)}
        scrollable
        scrollHeight="16.813rem"
        className={`meter-modal-datatable ${getHeaderCheckboxShowCssClass()}`}
      >
        <Column
          field="dateSummary"
          body={dateSummaryBodyTemplate}
          header={getHeaderNode('date')}
          style={{ width: '15%' }}
        />
        <Column
          field="estimate"
          body={estimateBodyTemplate}
          header={getHeaderNode('data')}
          style={{ width: '20%' }}
        />
        <Column
          field="source"
          body={sourceBodyTemplate}
          header={getHeaderNode('source')}
          style={{ width: '20%' }}
        />
        <Column
          field="consumption"
          body={consumptionBodyTemplate}
          header={getHeaderNode('consumption')}
          style={{ width: '40%' }}
        />
        <Column
          selectionMode="multiple"
          headerStyle={{ width: '5%' }}
          style={{ width: '5%' }}
        />
      </DataTable>
    )
  }

  const getNodataElement = () => (
    <div className="no-missing-data-container">
      <span className="data-quality-table-msg">
        {getLocalisedMsg('no_records_found')}
      </span>
    </div>
  )

  return (
    <Dialog
      className="dq-meter-dialog"
      visible={showDialog}
      header={getDqMeterModalHeaderElement()}
      onHide={onHideHandler}
      footer={getDqMeterModalFooterElement}
      blockScroll
    >
      <div className="dq-meter-modal-content">
        {summaries?.length > 0 ? getMeterDataTable() : getNodataElement()}
      </div>
    </Dialog>
  )
}
DqMeterModalV2.propTypes = {
  meterModalGapData: PropTypes.object,
  meterData: PropTypes.object,
  showDialog: PropTypes.bool,
  onHideHandler: PropTypes.func,
  onRequestDataHandler: PropTypes.func,
  onEnterDataHandler: PropTypes.func,
  metersSelected: PropTypes.array,
  updateSelectedMeters: PropTypes.func,
}

export default DqMeterModalV2