import {useCallback, useContext, useEffect} from 'react'
import {useHistory, useLocation, useParams} from 'react-router-dom'
import {saveAs} from 'file-saver'
import {pdf} from '@react-pdf/renderer'
import 'primeflex/primeflex.css'
import './DashboardHomeV3.scss'
import BackNavigation from '../../../../common/back-nav/BackNavigation'
import DashboardPdf from '../export-to-pdf/DashboardPdf'
import {FeatureFlagContext, PortfolioContext} from 'Contexts'
import I18n from '../../../../../utils/i18n/I18n'
import {createEndMonthParam, getFillerImage, getLocalizedValue, getUrlParams, unitsConverter} from '../../../../../utils/helpers/Helper'
import {periodOptions} from '../../../../../services/common/datepicker-service'
import {monthListShortWithLocale, PERIOD_TYPES, ASSET_DASHBOARD, Views, UtilityTypes, AREA, reportingPeriodMap, BOTH, LANDLORD, TENANT} from '../../../../../utils/helpers/Constants'
import {datePickerQueryValues, filterQueryParams} from '../../../../../utils/query-params/QueryParams'
import useAssetDashboardData from '../hooks-and-helpers/useAssetDashboardData'
import {setInitialAssetDashboardState} from '../hooks-and-helpers/dashboardHelper'
import useCustomState from '../../../../common/custom-hooks/useCustomState'
import UtilityTilesContainer from '../../../../common/tiles/UtilityTilesContainer'
import KeyFactsBar from '../../../../common/keyFacts/KeyFactsBar'
import PeriodView from '../../../../common/period-view/PeriodView'
import TargetPreviousVarianceLegend from '../../../../common/period-view/TargetPreviousVarianceLegend'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'
import DashboardCardsGrid from './sub-components/DashboardCardsGrid'
import DashboardCardsGridV2 from './sub-components/DashboardCardsGridV2'
import useAssetCoverageCompletenessData from '../../../../../utils/custom-hooks/data-quality/useAssetCoverageCompletenessData'

const DashboardHomeV3 = () => {
  const {instanceName, orgName, fundName, assetId} = useParams()
  const history = useHistory()
  const queryValues =  getUrlParams(useLocation().search)

  const { pfState, pfDispatcher } = useContext(PortfolioContext)
  const showTarget=pfState.showTarget
  const { loginState: { userInfo } } = useLoginAuthContext()
  const selectedLanguage = userInfo.languagePreference
  const unitSystem = userInfo.unitSystem
  const rowData = pfState.data
  const areaLabel = unitsConverter(AREA, unitSystem)
  const {customState: assetDashboardState, updateCustomState: updateAssetDashboardState} = useCustomState(() => setInitialAssetDashboardState(assetId, queryValues))
  const reportingPeriod = assetDashboardState?.timePeriod === PERIOD_TYPES.CUSTOM ? assetDashboardState?.timeNoOfMonthsPeriod : reportingPeriodMap[assetDashboardState.timePeriod]
  const {featureState: {sieraplus_assetDataQualityLayoutV2}} = useContext(FeatureFlagContext)

  const {
    assetHeaderInfo,
    dataqualityData,
    performanceData,
    adbActions,
    isLoadingActionData,
    assetData,
    assetKeyFactsData
  } = useAssetDashboardData(assetId, assetDashboardState.variablesDQResponsibility, assetDashboardState.variablesDPData, unitSystem, sieraplus_assetDataQualityLayoutV2)

  const responsibilities={
    landlord: LANDLORD, 
    tenant: TENANT,
    both: BOTH
  }
  const apiParams={
    timePeriod: assetDashboardState.timePeriod,
    timeNoOfMonthsPeriod: assetDashboardState.timeNoOfMonthsPeriod,
    timeEndDatePeriod: assetDashboardState.timeEndDatePeriod,
    utilitySelectedBoth: 'All',
    areaSelectedBoth: 'All',
    categorySelectedBoth: 'All',
    utilitySelectedLandlord: 'All',
    areaSelectedLandlord: 'All',
    categorySelectedLandlord: 'All',
    utilitySelectedTenant: 'All',
    areaSelectedTenant: 'All',
    categorySelectedTenant: 'All'
  }
  const {
    assetCompletenessBoth: {data: completenessData, isLoading: completenessDataLoading}, 
    assetCoverageBoth: {data: coverageData, isLoading: coverageDataLoading},
    assetCompletenessLandlord: {data: completenessDataLandlord},
    assetCompletenessTenant: {data: completenessDataTenant},
  } = useAssetCoverageCompletenessData(assetId, apiParams, responsibilities, sieraplus_assetDataQualityLayoutV2, unitSystem, true)

  //region useEffect Implementation
  useEffect(() => {
    if (assetHeaderInfo.data) {
      pfContext.pfDispatcher({...pfContext.pfState, ...{data: assetHeaderInfo.data}})
    } else if (Object.keys(rowData).length === 1) {
      assetHeaderInfo.refetch()
    }
  }, [assetHeaderInfo])

  useEffect(() => {
    updateAssetDashboardState({dashboardFile: getLocalizedValue(selectedLanguage, 't_dashboard_pdf') })
  }, [selectedLanguage])

  useEffect(() => {
    if(assetDashboardState.timePeriod === 'CUS') {
      updateAssetDashboardState({
        variablesDQResponsibility: ['dqGraphOverviewData', assetId, assetDashboardState.time, assetDashboardState.timeNoOfMonthsPeriod, assetDashboardState.timeEndDatePeriod],
        variablesDPData: ['dashboardPerformanceData', assetId, assetDashboardState.time, assetDashboardState.timeNoOfMonthsPeriod, assetDashboardState.timeEndDatePeriod]
      })
    } else {
      updateAssetDashboardState({
        variablesDQResponsibility: ['dqGraphOverviewData', assetId, assetDashboardState.time],
        variablesDPData: ['dashboardPerformanceData', assetId, assetDashboardState.time]
      })
    }
  },[assetDashboardState.time, assetDashboardState.timePeriod, assetDashboardState.timeNoOfMonthsPeriod, assetDashboardState.timeEndDatePeriod, assetDashboardState.pickerValue ])

  useEffect(() => {
    if (assetData.data) {
      assetInfo = assetData.data?.assetInfo
      let cert = []
      assetInfo.certifications.map((certification, index) => {
        if (index < 2) {
          cert.push(certification.name + ' - ' + certification.rating)
        }
      })
      const refub = assetInfo.lastRefurbished ? assetInfo.lastRefurbished : '-'
      updateAssetDashboardState({
        certifications: cert,
        lastRefurbished: refub
      })
    }
  }, [assetData.data])

  //For effects
  useEffect(() => {
    let datePickerValues = datePickerQueryValues(assetDashboardState.timePeriod, assetDashboardState.pickerValue)
    const queryParams = {
      timePeriod: datePickerValues.timePeriodSelected,
      noOfMonths: datePickerValues.noOfMonths,
      endMonth: datePickerValues.endMonth,
      endYear: datePickerValues.endYear
    }
    filterQueryParams(history, queryParams, rowData)
  }, [assetDashboardState.timePeriod, assetDashboardState.pickerValue.pickerValueEndMonth, assetDashboardState.pickerValue.pickerValueMonths, assetDashboardState.pickerValue.pickerValueEndYear])

  //endregion

  //region Helper methods
  const getPeriodMenuItem = (option) => (option === PERIOD_TYPES.YTD)?<span className='p-button-label p-c period-menu-ytd-tooltip' data-pr-position="top">{option}
  </span>:<span className='p-button-label p-c'>{option}</span>

  let assetInfo = {}

  let propertyImagePdf=getFillerImage( rowData, false,true)

  async function handleExport(){
    const selectedTimePeriod=I18n(assetDashboardState.pickerValue.pickerValueMonths === '1' ? 't_picker_filter_value_single_month' : 't_picker_filter_value', {
      0: assetDashboardState.pickerValue.pickerValueMonths,
      1: monthListShortWithLocale[assetDashboardState.pickerValue.pickerValueEndMonth],
      2: assetDashboardState.pickerValue.pickerValueEndYear
    })
    const dqData = sieraplus_assetDataQualityLayoutV2 ? {completenessDataLandlord, completenessDataTenant} : dataqualityData?.data
    const doc = <DashboardPdf asset={assetData.data.assetInfo} assetImage={propertyImagePdf} utility={performanceData.data?.consumptionYtdTotals}
      lastRefurbished={assetDashboardState.lastRefurbished} certifications={assetDashboardState.certifications} actionPlan={adbActions.data}
      timePeriod={assetDashboardState.timePeriod === 'CUS' ? selectedTimePeriod : assetDashboardState.timePeriod} showTarget={showTarget}
      dataQuality={dqData} dashboard={true} language={selectedLanguage} unitSystem={userInfo.unitSystem} assetDQV2={sieraplus_assetDataQualityLayoutV2}/> 
    const asPdf = pdf([]) // {} is important, throws without an argument
    asPdf.updateContainer(doc)
    const blob =  await asPdf.toBlob()
    saveAs(blob, assetDashboardState.dashboardFile)
  }
  //endregion

  const getFundCommonProps=useCallback(function(){
    return {
      instanceName,
      orgName,
      fundName,
      pfState,
      pfDispatcher,
      reportingPeriod
    }
  }, [instanceName, orgName, fundName, pfState, reportingPeriod])

  function getUtilityTilesContainerProps() {
    const consumptionData=performanceData.data??{}

    const utilityData={
      assetId: consumptionData.assetId,
      carbonEmission: consumptionData?.consumptionYtdTotals?.find(consumptionData=>(UtilityTypes.Carbon===consumptionData.utilitySummary)),
      energyIntensity: consumptionData?.consumptionYtdTotals?.find(consumptionData=>(UtilityTypes.Energy===consumptionData.utilitySummary)),
      electricity: consumptionData?.consumptionYtdTotals?.find(consumptionData=>(UtilityTypes.Electricity===consumptionData.utilitySummary)),
      gasFuelsAndThermals: consumptionData?.consumptionYtdTotals?.find(consumptionData=>(UtilityTypes.GasFuelsAndThermals===consumptionData.utilitySummary)),
      water: consumptionData?.consumptionYtdTotals?.find(consumptionData=>(UtilityTypes.Water===consumptionData.utilitySummary)),
      recycledWaste: consumptionData?.consumptionYtdTotals?.find(consumptionData=>(UtilityTypes.Waste===consumptionData.utilitySummary)),
    }

    return {
      performanceData: utilityData,
      isLoadingPerformanceData: performanceData.isLoading,
      modalState: assetDashboardState.modalState,
      updateFundDashboardState: updateAssetDashboardState,
      getFundCommonProps: getFundCommonProps,
      view: Views.AssetDashboard
    }
  }

  return (
    <div data-page_identifier="asset_dashboard" className="dashboard-home" data-testid={'asset-dashboard'}>
      <div className="container-layout gutter">
        <div role={'back-navigation'}>
          <BackNavigation
            exportName="Export as PDF"
            onExportClick={handleExport}
            disableExportButton = {assetData.isLoading}
            period={assetDashboardState.timePeriod}
            options={periodOptions}
            datePickerOnChange={(e) => updateAssetDashboardState({timePeriod: e.value})}
            customDatePickerOnClick={() => {}}
            customDateSummary={assetDashboardState.timePeriod === PERIOD_TYPES.CUSTOM}
            itemTemplate={getPeriodMenuItem}
            pickerValue={assetDashboardState.pickerValue}
            componentName={ASSET_DASHBOARD}
            showVerticalEllipsis={true}
            bulkUploadConsumptionId={assetId}
            routeVia={ASSET_DASHBOARD}
            pickerOnChange={e => {
              const pickerValueUpdated = {...assetDashboardState.pickerValue, [e?.target?.name]: e?.target?.value}
              updateAssetDashboardState({
                timeNoOfMonthsPeriod: pickerValueUpdated.pickerValueMonths,
                timeEndDatePeriod: createEndMonthParam(pickerValueUpdated.pickerValueEndYear, pickerValueUpdated.pickerValueEndMonth),
                timePeriod: PERIOD_TYPES.CUSTOM,
                pickerValue: pickerValueUpdated
              })
            }}
            hideTimePeriodFilter={false}
            isNewLayout = {true}
          />
        </div>
        <div className="asset-dashboard">
          <div className="asset-dashboard__keyfacts">
            <KeyFactsBar keyFactsData = {assetKeyFactsData?.data} selectedLanguage={selectedLanguage} areaLabel={areaLabel} isLoading={assetKeyFactsData.isLoading} isNewLayout={true}/>
          </div>
          <div className="asset-dashboard__utility-overview">
            <div className="asset-dashboard__utility-overview__header">
              <div className="asset-dashboard__utility-overview__header__col-1 flex">
                <span className="asset-dashboard__utility-overview__header__col-1__title">{getLocalizedValue(selectedLanguage, 't_performance_overview')}</span>
                <PeriodView timePeriod={assetDashboardState?.timePeriod} pickerValue={assetDashboardState?.pickerValue} />
              </div>
              <TargetPreviousVarianceLegend selectedLanguage={selectedLanguage} />
            </div>
            <UtilityTilesContainer {...getUtilityTilesContainerProps()} />
          </div>
          <div className="asset-dashboard__data-overview">
            { !sieraplus_assetDataQualityLayoutV2 ?
              <DashboardCardsGrid
                dataQualityData={dataqualityData.data}
                dataQualityDataLoading={dataqualityData.isLoading}
                timePeriod={assetDashboardState?.timePeriod}
                pickerValue={assetDashboardState?.pickerValue}
                isLoadingActionData={isLoadingActionData}
                adbActions={adbActions}
                selectedLanguage={selectedLanguage}
              />
              :
              <DashboardCardsGridV2
                completenessData={completenessData}
                completenessDataLoading={completenessDataLoading}
                coverageData={coverageData}
                coverageDataLoading={coverageDataLoading}
                timePeriod={assetDashboardState?.timePeriod}
                pickerValue={assetDashboardState?.pickerValue}
                selectedLanguage={selectedLanguage}
                areaUnit={areaLabel}
              />
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default DashboardHomeV3

DashboardHomeV3.displayName = 'DashboardHomeV3'
