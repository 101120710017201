import {
  convertToExcelExportFormat,
  formatDateToDDMMYY,
  getValidData,
  UTILITY_MAP_FOR_PERFORMANCE_EXPORT,
  getRequiredUnit,
  UNIT_TYPE_FOR_EXPORT,
} from '../../ExportHelper'
import { UTILITY_LABELS_OBJECT_TITLE_CASE } from '../../../helpers/Constants'
import { isObject, rangeSelectionMonthly, getMonthlyConsumption } from './monthlyAssetPerformanceExportHelper'

export function getMeterMonthlyExcelHeader(utility) {
  let commonTableHeaders1 = [
    {key: 't_asset_ref'}, {key: 't_asset_name'}, {key: 't_fund'}, { key: 't_country'},{key: 't_city'},{key: 't_sector'}, {key: 't_gresb_sector'}, { key: 't_purchase_date'},{key: 't_sale_date'}]
  let commonTableHeaders2 = [
    {key: 't_meter_name'}, {key: 't_serial_number'}, {key: 't_comment'}, { key: 't_area_covered'},{key: 't_procured_by'}, {key: 't_status'}, { key: 't_active_from'},{key: 't_active_to'}, {key: 't_is_this_a_submeter'}]

  const totalConsumptionAndCarbonEmission = [
    {key: 't_total_consumption',unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.ENERGY_CONSUMPTION, null, null)},
    {key: 't_total_carbon_emissions', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
    {key: 't_total_consumption',unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.ENERGY_CONSUMPTION, null, null)},
    {key: 't_total_carbon_emissions', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
  ]

  switch (utility) {
  case UTILITY_LABELS_OBJECT_TITLE_CASE.CARBON :
    return [...commonTableHeaders1,  {key: 't_meter_name'}, {key: 't_serial_number'},  {key: 't_utility_type'},...commonTableHeaders2.slice(2),
      {key: 't_floor_area_served',unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.AREA, null, null)},{key: 't_green_tariff'}, {key: 't_reporting_period'},{key: 't_scope'},{key: 't_total_emission_excel', unit : getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},{key: 't_total_emission_excel', unit : getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
    ]

  case UTILITY_LABELS_OBJECT_TITLE_CASE.ELECTRICITY :

    return  [...commonTableHeaders1, ...commonTableHeaders2,
      {key: 't_floor_area_served', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.AREA, null, null)}, {key: 't_green_tariff'},{key: 't_reporting_period'},
      ...totalConsumptionAndCarbonEmission,
      {key: 't_scope'}]

  case UTILITY_LABELS_OBJECT_TITLE_CASE.WATER :
    return  [...commonTableHeaders1, ...commonTableHeaders2,{key: 't_floor_area_served', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.AREA, null, null)},
      {key: 't_reporting_period'},{key: 't_total_consumption',unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.WATER_CONSUMPTION, null, null)},
      {key: 't_total_consumption',unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.WATER_CONSUMPTION, null, null)}
    ]

  case UTILITY_LABELS_OBJECT_TITLE_CASE.WASTE :
    return [...commonTableHeaders1,  {key: 't_waste_disposal_route_reference'},  {key: 't_contractor'},
      {key: 't_description'},  {key: 't_waste_type'},{key: 't_reporting_period'},  {key: 't_month'},{key: 't_hazardous_waste', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_non_hazardous_waste', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_total_waste_generated', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_landfill_unit', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},{key: 't_incinerated', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_reuse_unit', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},{key: 't_waste_to_energy_unit', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_recycled_unit', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},{key: 't_unknown_unit', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)}]

  case UTILITY_LABELS_OBJECT_TITLE_CASE.ENERGY :
    return  [...commonTableHeaders1,
      {key: 't_utility_type'},
      ...commonTableHeaders2,{key: 't_floor_area_served_by_meter',unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.AREA, null, null)},
      {key: 't_green_tariff'}, {key: 't_reporting_period'},...totalConsumptionAndCarbonEmission,{key: 't_scope'}]

  case UTILITY_LABELS_OBJECT_TITLE_CASE.GAS_FUEL_THERMAL :
    return  [...commonTableHeaders1,{key: 't_utility_type'},...commonTableHeaders2,
      {key: 't_floor_area_served', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.AREA, null, null)},{key: 't_green_tariff'}, {key: 't_reporting_period'},
      ...totalConsumptionAndCarbonEmission,{key: 't_scope'}]
  }
}

const hasCheckMeterMonthlyGapField = (utilityConsumptionInfo) => {
  const { monthlyConsumption, currentCarbonEmission, currentGapFilledCarbonEmission } = utilityConsumptionInfo
  const hasCheckMonthlyConsumption = isObject(monthlyConsumption) && !Object.keys(monthlyConsumption).length || monthlyConsumption === null
  return !!(currentCarbonEmission !== null && currentGapFilledCarbonEmission !== null && hasCheckMonthlyConsumption)
}

export function getMeterMonthlyExcelData(utility, assetTableData,selectedLanguage, timePeriod, pickerValue, assetTableColumnNamesWithLocalization, intl) {
  function getAssetTableRowData(utilityConsumptionInfo, utility,asset){

    let commonTableColumnValuesSet1 = [
      asset.assetReference,getValidData(asset.name, selectedLanguage, false, false),
      getValidData(asset.fundName, selectedLanguage, false, false),getValidData(asset.assetLocation, selectedLanguage),
      getValidData(asset.assetCity, selectedLanguage),
      getValidData(asset.sector, selectedLanguage),getValidData(asset.gresbSector, selectedLanguage),
      formatDateToDDMMYY(asset.assetPurchaseDate, selectedLanguage),formatDateToDDMMYY(asset.dateOfSale, selectedLanguage),
    ]

    let commonTableColumnValuesSet2 = [
      getValidData(utilityConsumptionInfo.meterName, selectedLanguage, false, false),
      getValidData(utilityConsumptionInfo.serialNumber, selectedLanguage),getValidData(utilityConsumptionInfo.meterComment, selectedLanguage, false, false),
      getValidData(utilityConsumptionInfo.areaCovered, selectedLanguage),getValidData(utilityConsumptionInfo.procuredBy, selectedLanguage),
      getValidData(utilityConsumptionInfo.meterStatus, selectedLanguage),getValidData(utilityConsumptionInfo.activeFrom, selectedLanguage),
      getValidData(utilityConsumptionInfo.activeTo, selectedLanguage),getValidData(utilityConsumptionInfo.isSubMeter, selectedLanguage),
    ]

    function mappedMonthlyConsumption(){
      // commented as per ticket 7789 will be enabled in future
      // if(hasCheckMeterMonthlyGapField(utilityConsumptionInfo)){
      //   const getMonthOfObject = getNumberOfMonthsAndEndMonth(timePeriod, pickerValue)
      //   const monthNumber = getMonthOfObject?.numberOfMonths - 1 || 0
      //   return [...getLocalizedColumnNamesWithUnits([{key: translationKey }], intl), ...new Array(monthNumber).fill(' - ')]
      // }
      return getMonthlyConsumption(timePeriod,pickerValue,utilityConsumptionInfo)
    }

    switch (utility) {
    case UTILITY_LABELS_OBJECT_TITLE_CASE.CARBON:
      return [
        ...commonTableColumnValuesSet1,
        getValidData(utilityConsumptionInfo.meterName, selectedLanguage),
        getValidData(utilityConsumptionInfo.serialNumber, selectedLanguage),
        getValidData(utilityConsumptionInfo.utilityType, selectedLanguage),
        ...commonTableColumnValuesSet2.slice(2),
        getValidData(utilityConsumptionInfo.nlaServed, selectedLanguage),
        getValidData(utilityConsumptionInfo.greenTariff, selectedLanguage),
        getValidData(utilityConsumptionInfo.reportingPeriod, selectedLanguage),
        getValidData(utilityConsumptionInfo.scope, selectedLanguage),
        ...mappedMonthlyConsumption(),
        getValidData(utilityConsumptionInfo.currentCarbonEmission, selectedLanguage), 
        getValidData(utilityConsumptionInfo.currentGapFilledCarbonEmission, selectedLanguage)
      ]

    case UTILITY_LABELS_OBJECT_TITLE_CASE.ELECTRICITY:
      return [
        ...commonTableColumnValuesSet1, 
        ...commonTableColumnValuesSet2,
        getValidData(utilityConsumptionInfo.nlaServed, selectedLanguage),
        getValidData(utilityConsumptionInfo.greenTariff, selectedLanguage),
        getValidData(utilityConsumptionInfo.reportingPeriod, selectedLanguage),
        ...mappedMonthlyConsumption(),
        getValidData(utilityConsumptionInfo.currentConsumption, selectedLanguage),
        getValidData(utilityConsumptionInfo.currentCarbonEmission, selectedLanguage),
        getValidData(utilityConsumptionInfo.currentGapFilled, selectedLanguage),
        getValidData(utilityConsumptionInfo.currentGapFilledCarbonEmission, selectedLanguage),
        getValidData(utilityConsumptionInfo.scope, selectedLanguage),
      ]

    case UTILITY_LABELS_OBJECT_TITLE_CASE.WATER:
      return [
        ...commonTableColumnValuesSet1, 
        ...commonTableColumnValuesSet2,
        getValidData(utilityConsumptionInfo.nlaServed, selectedLanguage),
        getValidData(utilityConsumptionInfo.reportingPeriod, selectedLanguage),
        ...mappedMonthlyConsumption(),
        getValidData(utilityConsumptionInfo.currentConsumption, selectedLanguage), 
        getValidData(utilityConsumptionInfo.currentGapFilled, selectedLanguage)]

    case UTILITY_LABELS_OBJECT_TITLE_CASE.WASTE:
      return [
        ...commonTableColumnValuesSet1,getValidData(utilityConsumptionInfo.wasteDestination, selectedLanguage),getValidData(utilityConsumptionInfo.wasteContractor, selectedLanguage),
        getValidData(utilityConsumptionInfo.wasteCollectionDescription, selectedLanguage),getValidData(utilityConsumptionInfo.wasteStream, selectedLanguage),
        getValidData(utilityConsumptionInfo.reportingPeriod, selectedLanguage),getValidData(utilityConsumptionInfo.month, selectedLanguage),
        getValidData(utilityConsumptionInfo?.hazardous, selectedLanguage),getValidData(utilityConsumptionInfo?.nonHazardous, selectedLanguage),
        getValidData(utilityConsumptionInfo?.tonnes, selectedLanguage),getValidData(utilityConsumptionInfo?.landfill, selectedLanguage),
        getValidData(utilityConsumptionInfo?.incinerated, selectedLanguage),
        getValidData(utilityConsumptionInfo?.reuse, selectedLanguage),getValidData(utilityConsumptionInfo?.incineratedWer, selectedLanguage),
        getValidData(utilityConsumptionInfo?.recycled, selectedLanguage),getValidData(utilityConsumptionInfo?.mrfUnknown, selectedLanguage)
      ]

    case UTILITY_LABELS_OBJECT_TITLE_CASE.GAS_FUEL_THERMAL:
    case UTILITY_LABELS_OBJECT_TITLE_CASE.ENERGY:
      return [
        ...commonTableColumnValuesSet1, 
        getValidData(utilityConsumptionInfo.utilityType, selectedLanguage),
        ...commonTableColumnValuesSet2,
        getValidData(utilityConsumptionInfo.nlaServed, selectedLanguage),
        getValidData(utilityConsumptionInfo.greenTariff, selectedLanguage),
        getValidData(utilityConsumptionInfo.reportingPeriod, selectedLanguage),
        ...mappedMonthlyConsumption(),
        getValidData(utilityConsumptionInfo.currentConsumption, selectedLanguage),
        getValidData(utilityConsumptionInfo.currentCarbonEmission, selectedLanguage),
        getValidData(utilityConsumptionInfo.currentGapFilled, selectedLanguage),
        getValidData(utilityConsumptionInfo.currentGapFilledCarbonEmission, selectedLanguage),
        getValidData(utilityConsumptionInfo.scope, selectedLanguage)
      ]
    }
  }

  // execution starts here...
  let assetTableRowCount = 0
  const assetTableRowsData = []
  const getRangeDataSelection = []

  if(assetTableData?.length > 0){
    assetTableData.forEach((asset) => {
      const getUtilityConsumption = asset[UTILITY_MAP_FOR_PERFORMANCE_EXPORT[utility]]
      if(getUtilityConsumption.length > 0) {
        getUtilityConsumption.forEach(item => {
          assetTableRowCount += 1
          let assetTableRowData = getAssetTableRowData(item, utility, asset)
          const getRangeSelection = rangeSelectionMonthly(item.monthlyConsumption, assetTableRowsData.length, assetTableColumnNamesWithLocalization)
          if(getRangeSelection){
            getRangeDataSelection.push(getRangeSelection)
          }
          assetTableRowsData.push(convertToExcelExportFormat(assetTableRowData))
        })
      }
    })
  }
  return {assetTableRows: assetTableRowsData, assetTableNumberOfRows: assetTableRowCount, rangeDataSelection: getRangeDataSelection}
}