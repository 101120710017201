import React from 'react'
import { Calendar as PrimeCalendar } from 'primereact/calendar'
import Icon from '../Icon/Icon'
import PropTypes from 'prop-types'
import './Calendar.scss'
import { addLocale } from 'primereact/api'
import Text from '../Text/Text'

const Calendar = ({
  className,
  closeIcon,
  dateFormat,
  disabled,
  id,
  name,
  label,
  maxDate,
  minDate,
  onBlur,
  onChange,
  appendSelf,
  onHide,
  onClick,
  panelClassName,
  placeholder,
  value,
  selectionMode,
  size,
  testId,
  disabledDates,
  view = 'date'
}) => {
  addLocale('en', {
    firstDayOfWeek: 1
  })

  const getCssClass = (className, selectionMode, size) => {
    let cssClass = ['siera-calendar']
    if (className) {
      cssClass.push(className)
    }
    if (selectionMode) {
      cssClass.push(selectionMode)
    }
    if (size) {
      cssClass.push(size)
    }
    return cssClass.join(' ')
  }


  const handleCalendarFocus = () => {
    const datePickerCollection =
      document.getElementsByClassName('p-datepicker')
    if (datePickerCollection.length) {
      const datePicker = datePickerCollection.item(0)
      datePicker.setAttribute('tabindex', '0')
      datePicker.focus()
    }
  }

  return (
    <div className={`siera-calendar-container ${size}`}>
      {label && (
        <label htmlFor={id} className='siera-calendar__label'>
          <Text colour="faded_teal" content={label} />
        </label>
      )}
      <PrimeCalendar
        className={className}
        onFocus={handleCalendarFocus}
        data-testid={testId}
        placeholder={placeholder}
        value={value}
        showIcon
        icon={<Icon id='calendar' size={17} />}
        id={id}
        appendTo={appendSelf ? 'self' : null}
        selectionMode={selectionMode}
        readOnlyInput={false}
        name={name}
        dateFormat={dateFormat}
        disabled={disabled}
        minDate={minDate}
        maxDate={maxDate}
        onClick={onClick}
        panelClassName={getCssClass(panelClassName, selectionMode, size)}
        onHide={onHide}
        onBlur={onBlur}
        onChange={onChange}
        view={view}
        disabledDates={disabledDates}
      />
    </div>
  )
}

export default Calendar

Calendar.propTypes = {
  /**
   * Additional CSS class to add to the calendar
   */
  className: PropTypes.string,
  /**
   * Format in which date must be displayed upon selection
   */
  dateFormat: PropTypes.string,
  /**
   * Property to enable/disable the calendar
   */
  disabled: PropTypes.bool,
  /**
   * Unique identifier for the calendar
   */
  id: PropTypes.string,
  /**
   * Label for the calendar: The translation id (ie. 't_assets').
   * Entire list of keys can be found here: src\resources\messages\en-GB.js.
   * For scenarios where translation is not necessary (email addresses, asset/meter/fund/user names, etc) we just pass the raw data as the label.
   */
  label: PropTypes.string,
  /**
   * Name of the calendar input
   */
  name: PropTypes.string,
  /**
   * Maximum date that must be allowed to be selected on the calendar: must be an instance of date, when passed
   */
  maxDate: PropTypes.instanceOf(Date),
  /**
   * Minimum date that must be allowed to be selected on the calendar: must be an instance of date, when passed
   */
  minDate: PropTypes.instanceOf(Date),
  /**
   * Callback function that gets invoked when user clicks outside after making selections on the calendar
   */
  onBlur: PropTypes.func,
  /**
   * Callback function that gets invoked when user makes any selection on the calendar
   */
  onChange: PropTypes.func,
  /**
   * Callback function that gets invoked when user clicks on the calendar
   */
  onClick: PropTypes.func,
  /**
   * Callback function that gets invoked when the calendar is closed
   */
  appendSelf: PropTypes.bool,
  /**
   * boolean to append to self
   */
  onHide: PropTypes.func,
  /**
   * CSS class of the calendar panel
   */
  panelClassName: PropTypes.string,
  /**
   * Placeholder text for the calendar input
   */
  placeholder: PropTypes.string,
  /**
   * Nullable property of the calendar: it takes one of the types defined below, when a value is passed
   */
  value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.array, PropTypes.string]),
  /**
   * Defines what kind of date selection is allowed on the calendar
   */
  selectionMode: PropTypes.oneOf(['range', 'single']),
  /**
   * Array of dates that must be disabled on the calendar
   */
  disabledDates: PropTypes.array,
  size: PropTypes.oneOf(['regular', 'small']),
  testId: PropTypes.string,
  view: PropTypes.string,
  closeIcon: PropTypes.bool
}

Calendar.defaultProps = {
  dateFormat: 'dd M yy',
  selectionMode: 'single',
  size: 'regular'
}

Calendar.displayName = 'Calendar'