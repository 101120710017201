import {
  convertToExcelExportFormat,
  formatDateToDDMMYY,
  getValidData,
  UTILITY_MAP_FOR_PERFORMANCE_EXPORT,
  getRequiredUnit,
  UNIT_TYPE_FOR_EXPORT,
  getExcelCellName,
} from '../../ExportHelper'
import { UTILITY_LABELS_OBJECT_TITLE_CASE } from '../../../helpers/Constants'
import {  electricityAndGasFuelThermalGapHeading, waterConsumptionGapHeading,
  energyConsumptionGapHeading, mappedAnnualCurrentConsumption,
} from './assetPerformanceCommonExportHelper'

function annualMeterRangeSelection(index, columnName, consumptionKeysName){
  if(Array.isArray(columnName)){
    const getIndex = columnName.findIndex(item => consumptionKeysName.includes(item.key))
    if(getIndex !== -1){
      return {
        start: getExcelCellName(getIndex + 1), end: getExcelCellName(columnName.length), index
      }
    }
  }
  return null
}

function hasCheckConsumptionDataForMeterAnnual(utilityConsumptionInfo){
  const { currentConsumption, previousConsumption, currentGapFilled, previousGapFilled} = utilityConsumptionInfo
  return (currentConsumption === null && previousConsumption === null && currentGapFilled === null && previousGapFilled === null)
}

function getConsumptionMeterAnnualTableData(utilityConsumptionInfo, selectedLanguage, intl){
  if(hasCheckConsumptionDataForMeterAnnual(utilityConsumptionInfo)){
    return mappedAnnualCurrentConsumption(null, selectedLanguage, intl, 't_no_meter_data_available')
  }
  return getValidData(utilityConsumptionInfo?.currentGapFilled, selectedLanguage)
}

function meterAnnualGapFieldValue(utilityConsumptionInfo, selectedLanguage, intl){
  return [
    getConsumptionMeterAnnualTableData(utilityConsumptionInfo, selectedLanguage, intl),
    getValidData(utilityConsumptionInfo?.previousGapFilled, selectedLanguage),
    getValidData(utilityConsumptionInfo?.currentGapFilledCarbonEmission, selectedLanguage),
    getValidData(utilityConsumptionInfo?.previousGapFilledCarbonEmission, selectedLanguage),
    getValidData(utilityConsumptionInfo?.currentConsumption, selectedLanguage),
    getValidData(utilityConsumptionInfo?.previousConsumption, selectedLanguage),
    getValidData(utilityConsumptionInfo?.currentCarbonEmission, selectedLanguage),
    getValidData(utilityConsumptionInfo?.previousCarbonEmission, selectedLanguage),
  ]
}

export function getColumnNameMeterAnnual(utility) {

  const meterCommonHeader = [{key: 't_asset_ref'}, {key: 't_asset_name'}, {key: 't_fund'}, { key: 't_country'},{key: 't_city'}, {key: 't_sector'}, {key: 't_gresb_sector'}, { key: 't_purchase_date'},{key: 't_sale_date'}]
  const commonTableHeaders2 = [
    {key: 't_meter_name'}, {key: 't_serial_number'}, {key: 't_comment'}, { key: 't_area_covered'},
    {key: 't_procured_by'}, {key: 't_status'}, { key: 't_active_from'},{key: 't_active_to'}, {key: 't_is_this_a_submeter'}]

  switch (utility) {
  case UTILITY_LABELS_OBJECT_TITLE_CASE.CARBON :
    return [...meterCommonHeader,  {key: 't_meter_name'}, {key: 't_serial_number'},  {key: 't_utility_type'},...commonTableHeaders2.slice(2),
      {key: 't_floor_area_served',unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.AREA, null, null)},
      {key: 't_green_tariff'}, {key: 't_scope'},{key: 't_current_total_carbon_emissions', unit : getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},{key: 't_previous_period_carbon_emissions', unit : getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_current_total_carbon_emissions', unit : getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},{key: 't_previous_period_carbon_emissions', unit : getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
    ]

  case UTILITY_LABELS_OBJECT_TITLE_CASE.ELECTRICITY :
    return  [...meterCommonHeader, ...commonTableHeaders2,
      {key: 't_floor_area_served', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.AREA, null, null)},
      {key: 't_green_tariff'},
      ...electricityAndGasFuelThermalGapHeading(),
      ...electricityAndGasFuelThermalGapHeading(),
      {key: 't_scope'}]

  case UTILITY_LABELS_OBJECT_TITLE_CASE.WATER :
    return  [...meterCommonHeader, ...commonTableHeaders2,
      {key: 't_floor_area_served', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.AREA, null, null)},
      ...waterConsumptionGapHeading(), 
      ...waterConsumptionGapHeading(),
    ]

  case UTILITY_LABELS_OBJECT_TITLE_CASE.WASTE :
    return [...meterCommonHeader,  {key: 't_waste_disposal_route_reference'},  {key: 't_contractor'},
      {key: 't_description'},  {key: 't_reporting_period'},  {key: 't_waste_type'},{key: 't_hazardous_waste', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_non_hazardous_waste', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_total_waste_generated', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},{key: 't_landfill_unit', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_incinerated', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_reuse_unit', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},{key: 't_waste_to_energy_unit', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},
      {key: 't_recycled_unit', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)},{key: 't_unknown_unit', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.EMISSION, null, null)}]

  case UTILITY_LABELS_OBJECT_TITLE_CASE.ENERGY :
    return  [...meterCommonHeader,
      {key: 't_total_asset_floor_area', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.AREA, null, null)},
      {key: 't_utility_type'}, ...commonTableHeaders2,
      {key: 't_floor_area_served_by_meter',unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.AREA, null, null)},
      {key: 't_green_tariff'},
      ...energyConsumptionGapHeading(), ...energyConsumptionGapHeading(),
      {key: 't_scope'}]

  case UTILITY_LABELS_OBJECT_TITLE_CASE.GAS_FUEL_THERMAL :
    return  [...meterCommonHeader,{key: 't_meter_name'},{key: 't_serial_number'},{key: 't_utility_type'},
      ...commonTableHeaders2.slice(2), {key: 't_floor_area_served', unit:getRequiredUnit(UNIT_TYPE_FOR_EXPORT.AREA, null, null)},
      {key: 't_green_tariff'},
      ...electricityAndGasFuelThermalGapHeading(), ...electricityAndGasFuelThermalGapHeading(),
      {key: 't_scope'}]
  }
}

function hasCheckMeterAnnualCarbonConsumption(utilityConsumptionInfo, utility){
  return (UTILITY_LABELS_OBJECT_TITLE_CASE.CARBON === utility && utilityConsumptionInfo?.currentCarbonEmission === null && utilityConsumptionInfo?.currentGapFilledCarbonEmission === null)
}

export function getTableDataMeterAnnual(utility, assetTableData, selectedLanguage, assetTableColumnNamesWithLocalization, intl) {
  function getAssetTableRowData(utilityConsumptionInfo, utility, asset){

    let commonAssetTableRowDataSet1 = [
      getValidData(asset?.assetReference, selectedLanguage, false, false),getValidData(asset?.name, selectedLanguage, false, false),getValidData(asset?.fundName, selectedLanguage, false, false),getValidData(asset?.assetLocation, selectedLanguage),
      getValidData(asset.assetCity, selectedLanguage), getValidData(asset?.sector, selectedLanguage),
      getValidData(asset?.gresbSector, selectedLanguage),formatDateToDDMMYY(asset?.assetPurchaseDate, selectedLanguage),formatDateToDDMMYY(asset?.dateOfSale, selectedLanguage),
    ]
    let commonAssetTableRowDataSet2 = [
      getValidData(utilityConsumptionInfo?.meterName, selectedLanguage, false, false),getValidData(utilityConsumptionInfo?.serialNumber, selectedLanguage),getValidData(utilityConsumptionInfo?.meterComment, selectedLanguage, false, false),
      getValidData(utilityConsumptionInfo?.areaCovered, selectedLanguage),getValidData(utilityConsumptionInfo?.procuredBy, selectedLanguage),
      getValidData(utilityConsumptionInfo?.meterStatus, selectedLanguage),getValidData(utilityConsumptionInfo?.activeFrom, selectedLanguage),
      getValidData(utilityConsumptionInfo?.activeTo, selectedLanguage),getValidData(utilityConsumptionInfo?.isSubMeter, selectedLanguage),
    ]

    function getCarbonConsumptionData(){
      if(hasCheckMeterAnnualCarbonConsumption(utilityConsumptionInfo, utility)){
        return mappedAnnualCurrentConsumption(null, selectedLanguage, intl, 't_no_meter_data_available')
      }
      return getValidData(utilityConsumptionInfo?.currentGapFilledCarbonEmission, selectedLanguage)
    }

    switch (utility) {
    case UTILITY_LABELS_OBJECT_TITLE_CASE.CARBON :
      return [...commonAssetTableRowDataSet1, ...commonAssetTableRowDataSet2.slice(0,2),
        getValidData(utilityConsumptionInfo.utilityType, selectedLanguage),
        ...commonAssetTableRowDataSet2.slice(2), 
        getValidData(utilityConsumptionInfo.nlaServed, selectedLanguage),
        getValidData(utilityConsumptionInfo.greenTariff, selectedLanguage),
        getValidData(utilityConsumptionInfo.scope, selectedLanguage),
        getCarbonConsumptionData(),
        getValidData(utilityConsumptionInfo.previousGapFilledCarbonEmission, selectedLanguage),
        getValidData(utilityConsumptionInfo.currentCarbonEmission, selectedLanguage),
        getValidData(utilityConsumptionInfo.previousCarbonEmission, selectedLanguage),
      ]
    case UTILITY_LABELS_OBJECT_TITLE_CASE.ELECTRICITY :
      return [...commonAssetTableRowDataSet1, ...commonAssetTableRowDataSet2, 
        getValidData(utilityConsumptionInfo.nlaServed, selectedLanguage),
        getValidData(utilityConsumptionInfo.greenTariff, selectedLanguage),
        ...meterAnnualGapFieldValue(utilityConsumptionInfo, selectedLanguage, intl),
        getValidData(utilityConsumptionInfo.scope, selectedLanguage),
      ]
    case UTILITY_LABELS_OBJECT_TITLE_CASE.WATER :
      return [
        ...commonAssetTableRowDataSet1, 
        ...commonAssetTableRowDataSet2, 
        getValidData(utilityConsumptionInfo.nlaServed, selectedLanguage),
        getConsumptionMeterAnnualTableData(utilityConsumptionInfo, selectedLanguage, intl),
        getValidData(utilityConsumptionInfo?.previousGapFilled, selectedLanguage),
        getValidData(utilityConsumptionInfo?.currentConsumption, selectedLanguage),
        getValidData(utilityConsumptionInfo?.previousConsumption, selectedLanguage),
        
      ]
    case UTILITY_LABELS_OBJECT_TITLE_CASE.WASTE :
      return [...commonAssetTableRowDataSet1,getValidData(utilityConsumptionInfo.wasteDestination, selectedLanguage),getValidData(utilityConsumptionInfo.wasteContractor, selectedLanguage),
        getValidData(utilityConsumptionInfo.wasteCollectionDescription, selectedLanguage),getValidData(utilityConsumptionInfo.reportingPeriod, selectedLanguage),
        getValidData(utilityConsumptionInfo.wasteStream, selectedLanguage),getValidData(utilityConsumptionInfo?.hazardous, selectedLanguage),
        getValidData(utilityConsumptionInfo?.nonHazardous, selectedLanguage),getValidData(utilityConsumptionInfo?.tonnes, selectedLanguage),
        getValidData(utilityConsumptionInfo?.landfill, selectedLanguage),getValidData(utilityConsumptionInfo?.incinerated, selectedLanguage),
        getValidData(utilityConsumptionInfo?.reuse, selectedLanguage),getValidData(utilityConsumptionInfo?.incineratedWer, selectedLanguage),
        getValidData(utilityConsumptionInfo?.recycled, selectedLanguage),getValidData(utilityConsumptionInfo?.mrfUnknown, selectedLanguage)
      ]
    case UTILITY_LABELS_OBJECT_TITLE_CASE.ENERGY :
      return [...commonAssetTableRowDataSet1,
        getValidData(asset.floorArea, selectedLanguage),getValidData(utilityConsumptionInfo.utilityType, selectedLanguage),
        ...commonAssetTableRowDataSet2,
        getValidData(utilityConsumptionInfo.nlaServed, selectedLanguage),
        getValidData(utilityConsumptionInfo.greenTariff, selectedLanguage),
        ...meterAnnualGapFieldValue(utilityConsumptionInfo, selectedLanguage, intl),
        getValidData(utilityConsumptionInfo.scope, selectedLanguage),
      ]
    case UTILITY_LABELS_OBJECT_TITLE_CASE.GAS_FUEL_THERMAL :
      return [...commonAssetTableRowDataSet1,
        ...commonAssetTableRowDataSet2.slice(0,2),
        getValidData(utilityConsumptionInfo.utilityType, selectedLanguage),
        ...commonAssetTableRowDataSet2.slice(2), 
        getValidData(utilityConsumptionInfo.nlaServed, selectedLanguage),
        getValidData(utilityConsumptionInfo.greenTariff, selectedLanguage),
        ...meterAnnualGapFieldValue(utilityConsumptionInfo, selectedLanguage, intl),
        getValidData(utilityConsumptionInfo.scope, selectedLanguage),
      ]
    }
  }
  return getAnnualByMeterResponse(assetTableData, getAssetTableRowData, assetTableColumnNamesWithLocalization, utility)
}

function getExcelRangeConfig(utilityConsumptionInfo, utility, assetTableRows, assetTableColumnNamesWithLocalization){
  const rangeData = []
  const consumptionKeys = ['t_current_consumption_with_gapFilled', 't_current_energy_consumption_with_gapFilled_data', 't_current_period_consumption_with_gapFilled_data', 't_current_total_carbon_emissions']
  if(hasCheckConsumptionDataForMeterAnnual(utilityConsumptionInfo) || hasCheckMeterAnnualCarbonConsumption(utilityConsumptionInfo, utility)) {
    const getRangeSelection = annualMeterRangeSelection(assetTableRows.length, assetTableColumnNamesWithLocalization, consumptionKeys)
    if(getRangeSelection){
      rangeData.push(getRangeSelection)
    }
  }
  return rangeData
}

export function getAnnualByMeterResponse(assetTableData, getAssetTableRowData, assetTableColumnNamesWithLocalization, utility){
  let assetTableRows = []
  let assetTableNumberOfRows = 0
  let rangeDataSelection = [] 
  if(assetTableData){
    assetTableData.forEach((asset) => {
      let utilityConsumptionInfoOfAssetArray = asset[UTILITY_MAP_FOR_PERFORMANCE_EXPORT[utility]]
      if(utilityConsumptionInfoOfAssetArray.length > 0){
        utilityConsumptionInfoOfAssetArray.forEach((utilityConsumptionInfo) => {
          assetTableNumberOfRows+=1
          let assetTableRowData = getAssetTableRowData(utilityConsumptionInfo, utility, asset)
          rangeDataSelection = getExcelRangeConfig(utilityConsumptionInfo, utility, assetTableRows, assetTableColumnNamesWithLocalization)
          assetTableRows.push(convertToExcelExportFormat(assetTableRowData))
        })
      }
    })
  }
  return {assetTableRows,assetTableNumberOfRows, rangeDataSelection}
}