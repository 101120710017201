import {Skeleton} from 'primereact/skeleton'
import './DocumentsSkeleton.scss'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import React from 'react'
import '../../../pages/documents/documents-home/DocumentsHome.scss'
import I18n from '../../../../utils/i18n/I18n'

const MIN_WIDTH_18 = {minWidth: '18rem'}
const MIN_WIDTH_12 = {minWidth: '12rem'}
const MIN_WIDTH_10 = {minWidth: '10rem'}
const DocumentsSkeleton = () => {
  const emptyArray = Array.from({length: 7})
  const bodyTemplate = (width) => {
    return <Skeleton width={width}/>
  }

  return (
    <div className={'documents-wrapper documents-grid-skeleton'}>
      <DataTable value={emptyArray} className={'p-datatable-gridlines asset-action-plan-table__data-table'}>
        <Column selectionMode="multiple" style={{width: '2%'}}/>
        <Column field="fileName" header={I18n('t_filename')} body={bodyTemplate('15rem')} style={MIN_WIDTH_18}/>
        <Column field="documentType" header={I18n('t_document_type')} body={bodyTemplate('9rem')} style={MIN_WIDTH_12}/>
        <Column field="implementationDate" header={I18n('t_implementation_date')} body={bodyTemplate('7rem')} style={MIN_WIDTH_10}/>
        <Column field="dateOfUpload" header={I18n('t_date_of_upload')} body={bodyTemplate('7rem')} style={MIN_WIDTH_10}/>
        <Column field="uploadedBy" header={I18n('t_uploaded_by')} body={bodyTemplate('7rem')} style={MIN_WIDTH_10}/>
        <Column field="fileSize" header={I18n('t_document_size')} body={bodyTemplate('7rem')} style={MIN_WIDTH_10}/>
        <Column field="fundName" header={I18n('t_fundname')} body={bodyTemplate('9rem')} style={{minWidth: '12rem'}}/>
        <Column field="assetReference" header={I18n('t_asset_reference_tc')} body={bodyTemplate('9rem')} style={MIN_WIDTH_12}/>
        <Column field="assetName" header={I18n('t_asset_name_tc')} body={bodyTemplate('9rem')} style={MIN_WIDTH_12}/>
      </DataTable>
    </div>
  )
}

export default DocumentsSkeleton