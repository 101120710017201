import I18n from '../../../../utils/i18n/I18n'
import './DocumentsFooter.scss'
import PropTypes from 'prop-types'
import cn from 'classnames'
import InfoRedIcon from '../../../../resources/images/icon/icon-error-red.svg'
import React, {useRef, useState} from 'react'
import {getLocalizedValue} from '../../../../utils/helpers/Helper'
import ConfirmationPopup from '../../../common/popups/confirm-popup/ConfirmationPopup'
import {Button, Icon, LinkButton} from 'components/atomic'
import JSZip from 'jszip'
import {deleteDocument, downloadDocuments, fetchFile} from '../../../../services/documents/documents-service'
import {DocumentSidebar} from '../add-document/document-sidebar/DocumentSidebar'
import {downloadDocumentsRequestData, getFileName} from '../DocumentsUtil'
import {useMutation} from 'react-query'
import useErrorHandling from '../../../common/error-handling/ErrorHandling'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'

export const DocumentsFooter = ({showEdit = false, showDelete = false, selectedRows=[],setSelectedRows, tabIndex = 0,refetch, showDownload = false}) => {
  const isButtonDisabled = selectedRows.length === 0
  const [popupVisible, setPopupVisible] = useState(false)
  const deleteButtonRef = useRef(null)
  const {loginState: {userInfo}} = useLoginAuthContext()
  const selectedLanguage = userInfo.languagePreference
  const [loading,setLoading]=useState({isDownloading:false,isDeleting:false})
  const [showSidebar,setShowSidebar] = useState(false)
  const deleteDocuments = useMutation(deleteDocument)
  const {errorDialog, ShowErrorDialog, errorDialogVisible} = useErrorHandling()

  const deleteConfirmationMessage = selectedRows.length > 1
    ? getLocalizedValue(selectedLanguage, 't_confirm_delete_documents')
    : getLocalizedValue(selectedLanguage, 't_confirm_delete_document')

  const accept = async () => {
    const getPaths = selectedRows.map(individualRow=> individualRow.filePath)
    try {
      setLoading({isDeleting: true})
      await deleteDocuments.mutateAsync(getPaths)
      setSelectedRows([])
      setLoading({isDeleting: false})
      setPopupVisible(false)
      //Time out is added to delay the refetch
      await new Promise(resolve => setTimeout(resolve, 2000))
      refetch()
    }catch (error) {
      setLoading({isDeleting: false})
      setPopupVisible(false)
      errorDialog({title: I18n('t_error_title'), message: error.toString()})
    }
  }

  const reject = () => {
    setPopupVisible(false)
  }

  const onCancelKeyDown = (e) => {
    if(e.code === 'Enter') {
      reject()
    }
  }

  async function downloadDocs() {
    try{
      setLoading({isDownloading: true})
      const requestPayload = downloadDocumentsRequestData(selectedRows, tabIndex)
      const data = await downloadDocuments(requestPayload)
      if (data.length > 1) {
        let zip = new JSZip()
        let documentFolder = zip.folder(getLocalizedValue(selectedLanguage, 't_document_export'))
        const fetchPromises = data.map(async (fileUrl,index) => {
          const response =   await fetchFile(fileUrl)
          documentFolder.file(getFileName(requestPayload,index), response.data)
        })
        await Promise.all(fetchPromises)
        zip.generateAsync({type: 'blob'}).then(function (content) {
          saveAs(content, getLocalizedValue(selectedLanguage, 't_document_export'))
        })
      }else{
        const response =  await fetchFile(data[0])
        const fileName = requestPayload[0].split('/').pop()
        saveAs(response.data,fileName)
      }
      setLoading({isDownloading: false})
    }catch (e) {
      console.log('Unable to download')
      setLoading({isDownloading: false})
    }
  }

  return (
    <div role={'documents-footer'} className={'documents-footer-container'}>
      <ConfirmationPopup
        className={'documents-delete-popup'}
        dismissable={true}
        target={deleteButtonRef.current}
        visible={popupVisible}
        footer={(
          <div className={'delete-popup-footer'}>
            <LinkButton
              content={'t_delete_yes'}
              colour={'red'}
              testId={'confirm-delete'}
              onClick={accept} />

            <span className={'button-separator'}>/</span>

            <LinkButton
              content={'t_cancel'}
              testId={'cancel-delete'}
              onClick={reject} />
          </div>
        )}
        message={(
          <div className={'delete-popup'}>
            <div className={'delete-popup__header'}>
              <div className={'header-title-section'}>
                <img src={InfoRedIcon} alt={'confirm-delete'}
                  className="info-icon col-1 no-padding" />
                <span className={'delete-document-title'}>{I18n('t_delete_document')}</span>
              </div>
              <i className={'pi pi-times pointer-cursor'}
                onClick={reject}
                onKeyDown={onCancelKeyDown}/>
            </div>
            <div className={'delete-popup__content'} data-testid={'delete-confirmation-text'}>{deleteConfirmationMessage}</div>
          </div>
        )}
        onHide={()=>setPopupVisible(false)} />
      {showDownload && (
        <Button
          size={'small'}
          content='t_download_documents'
          outlined={true}
          onClick={downloadDocs}
          loading={loading.isDownloading}
          disabled={isButtonDisabled}
          icon={<Icon id='download' />}
          testId={'download-documents'} />
      )}
      {showEdit &&
      <Button className={cn({'ml-3' : showDownload})}
        size={'small'}
        content='t_edit'
        outlined={true}
        disabled={isButtonDisabled}
        testId={'edit-document'}
        onClick={()=>setShowSidebar(true)}
      />
      }
      {showDelete &&
      <Button
        ref={deleteButtonRef}
        outlined={true}
        colour={'red'}
        className={'ml-3'}
        size={'small'}
        loading={loading.isDeleting}
        content='t_delete'
        disabled={isButtonDisabled}
        testId={'delete-document'}
        onClick={() => setPopupVisible(true)}
      />
      }
      {showSidebar &&
      <DocumentSidebar
        isEdit
        setShowSidebar={setShowSidebar}
        setSelectedRows={setSelectedRows}
        showSidebar={showSidebar}
        selectedRows={selectedRows}
        refetch={refetch}
      />
      }
      {errorDialogVisible && <ShowErrorDialog/>}
    </div>
  )
}

DocumentsFooter.propTypes = {
  selectedRows: PropTypes.array,
  showEdit : PropTypes.bool,
  showDelete : PropTypes.bool,
  setSelectedRows : PropTypes.func,
  tabIndex: PropTypes.number,
  refetch:PropTypes.func,
  showDownload: PropTypes.bool
}
